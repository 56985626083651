import React from 'react';
import ErrorDisplay from './ErrorDisplay';
import {Trans, t} from '@lingui/macro';
import withDataHOC from '../dataProvider/withDataHOC';
import { GLOBAL_DATA } from '../../constants/globalData';
import withSessionHOC from '../sessionProvider/withSessionHOC';

/**
 * @fero
 */

class PageError extends React.PureComponent {
    render() {
        const {[GLOBAL_DATA.ERROR]: error, sessionError} = this.props;
        return <div className="text-center p-5">
            <h1><Trans>V e-shope sa vyskytla nečakaná chyba.</Trans></h1>
            <span><Trans>Skúste znovu načítať stránku, alebo kontaktujte, prosím, administrátora.</Trans></span>
            <div className="pt-5">
                <ErrorDisplay error={sessionError != null ? sessionError : error}/>
            </div>
        </div>;
    }
}

export default withSessionHOC()(
    withDataHOC([GLOBAL_DATA.ERROR])(PageError)
);